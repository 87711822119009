.winner {
  display: flex;
  position: absolute;
  width: 500px;
  height: 300px;
  background-color: rgb(230, 230, 230);
  margin: 130px auto;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  border-radius: 80px;
}