body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(157, 175, 141);
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: rgb(157, 175, 141);
}

.container {
  padding-top: 64px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  min-width: 80px;
  border: none;
  border-radius: 4px;
  background-color: #347251;
  color: #f8f8f8;
  font-size: 1.1rem;
  outline: 0;
  cursor: pointer;
  transition: ease-in-out color 0.3s;
}

.btn:hover {
  background-color: #235037;
  text-decoration: none;
}

/* This line makes the "Register" and "Login" buttons look like links */
.btn_asLink {
  padding: 0;
  border-width: 0;
  border-bottom: 1px solid #444;
  border-radius: 0;
  background: none;
  font: inherit;
  color: #444;
}

.btn_asLink:hover {
  background-color: transparent;
  color: #235037;
}

/* --------------------------------------------------------------------------------
Alert
-------------------------------------------------------------------------------- */

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}

.formPanel {
  box-sizing: border-box;
  width: 400px;
  max-width: 100%;
  padding: 25px 60px;
  margin: 0 auto 20px;
  background-color: #efefef;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.4);
}

input {
  margin: 5px;
  margin-bottom: 10px;
}

p a,
p a:visited {
  color: rgb(21, 128, 30);
  text-decoration: none;
  font-weight: 700;
  transition: ease-in-out color 0.3s;
}

p a:hover {
  color: rgb(25, 63, 38);
}
