.menuList {
  padding: 60px;
  width: 100%;
  max-width: 200px;
  overflow: hidden;

}
.menuList button {
  width: 100%;
  margin: 3px 0;
}

.menuButtons {
  display: flex;
  min-width: 120px;
  flex-flow: column nowrap;
  padding: 30px;
}

.greenButton {
  background-color: rgb(150, 202, 150);
}

.lineBreak {
  margin: 50px 0;
}

.menuList .newGameBtn {
  background-color: rgb(234, 241, 227);
  color: black;
}

.menuList .newGameBtn:hover {
  background-color: rgb(197, 204, 179);
  color: black;
}


@media screen and (max-width: 800px) {
  .menuList{
    padding: 0;
  }
  
}
@media screen and (max-width: 450px) {
  .menuList{
    margin-top: 50px;
  }
  
}