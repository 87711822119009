.square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12.5%;
  width: 12.5%;
  font-size: 10px;
  text-align: left;
}

.square .coordinate {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px;
  text-transform: uppercase;
}

.coordinate::selection, .coordinate::-moz-selection {
  user-select: none;
  pointer-events: none;
}


.coordinate {
  pointer-events: none;
}

.pieceImage {
  height: 55%;
  width: 55%;
}

svg {
  position: relative;
  width: 100%;
  height: 100%;
  filter: drop-shadow(-1px 3px 2px rgb(24, 24, 24));
}

.white {
  background: rgb(247, 208, 164);
}

.black {
  background: rgb(199, 141, 83);
}

.highlightLast {
  background-color: rgb(255, 253, 128);
}
.black.highlightLast {
  /* background-color: rgb(133, 133, 133); */
  background-color: rgb(240, 237, 93);

}


.legalMove {
  background-color: rgb(145, 193, 231);
}  
.black.legalMove {
  background-color: rgb(129, 174, 211);
}  

.black.selected, .white.selected {
  background-color: rgba(0, 223, 252, 0) ;
}  
