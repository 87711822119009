table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
th,
td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
  width: 70px;
}

th {
  background-color: #f0f0f0;
}

.gameInfo {
  margin-top: 70px;
  height: 500px;
  max-height: 650px;
  overflow-y: scroll;
  width: 100%;
  max-width:200px;
  overflow: auto;
  padding: 0 10px;
}

.gameInfo::-webkit-scrollbar {
  width: 12px;
}

.gameInfo::-webkit-scrollbar-thumb {
  background-color: #e60f0f00;
  border: 2px solid #616161;
  border-radius: 8px;
}
