#profileContainer,
.profile-page {
  padding-top: 68px;
}

.profile-page {
  display: flex;
  justify-content: center;
  flex-flow: column;
  max-width: 60%;
  margin: 0 auto;
}

.profile-header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f0f0f0;
}

.profile-header img {
  border-radius: 5%;
  margin-right: 20px;
  width: 240px;
  height: 200px;
  /* padding-top: 30px; */
}

.profile-header textarea {
  flex: 1;
  height: 50px;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
}

.profile-header button {
  margin-top: 50px;
  font-size: 14px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 20px;
}

.profile-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.profile-tabs button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  border-radius: 20% 20% 0% 0%;
  background-color: #afafaf;
}

.profile-tabs button.active {
  background-color: #e7e7e7;
}

.profile-content {
  padding: 20px;
  background-color: #dfdddd;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  justify-content: center;
}

.profile-content ul {
  list-style: none;
  padding: 0;
}

.profile-content ul li {
  padding: 10px 0;
  font-size: 18px;
}
.flexyTable {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

#profileButtons {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  max-width: 300px;
}

.historyList {
  padding: 15px 25px;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.profileInfo {
  width: 100%;
}

@media screen and (max-width: 850px) {
  .profile-page {
    max-width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .profile-page {
    max-width: 250px;
  }
  .profile-header{
    flex-wrap: wrap;
  }
  .profile-header img {
    max-width: 100px;
    max-height: 80px;
  }
}